.sidebar-wrapper {
  position: unset;
  top: 0;
  height: 100vh;
}

.sidebar {
  top: 0;
  width: 0px;
  background-color: white;
  padding-top: 1rem;
  transition: 0.5s;
  z-index: 999;
  height: "100%";
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  border-right: 0.1px solid #ebebeb;
  height: 100%;
}

.icon-wrapper {
  width: 21px;
  text-align: center;
}

.nav-logo,
.nav-link {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
  color: gray;
}

.nav-link + .active {
  border-right: solid 3px var(--theme-color);
}

.nav-logo {
  margin-bottom: 2rem;
}

.nav-logo-icon {
  font-size: 1.25rem;
  color: var(--white-color);
  cursor: pointer;
}

.nav-logo-name {
  font-weight: 700;
  color: black;
}
.nav-link {
  font-size: 14px;
  position: relative;
  transition: 0.5s;
  margin-bottom: 0.5rem;
}

.sidebar-wrapper {
  .active {
    color: var(--theme-color) !important;
    background-color: var(--theme-color-alt);
  }

  .nav {
    flex-direction: column;
    overflow: hidden;
  }
  .nav-link:hover {
    color: var(--theme-color);
    background-color: var(--theme-color-alt);
  }
}

.showSidebar {
  width: var(--nav-expanded-width) !important;
}

@media screen and (min-width: 768px) {
  .sidebar {
    padding: 1rem 0 0 0;
    width: var(--nav-width);
    z-index: unset;
    position: unset;
  }

  .sidebar-wrapper {
    position: sticky;
  }
}

